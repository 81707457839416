export const colors = {
    LandingPage_Front : '#F1F2F1',
    LandingPage_Background: '#fff',
    LandingPage_Description: '#000000',
    Button_Primary: '#000000',
    Button_Secondary: '#000000', 
    Header_Background: "#FFFFFF",
    Header_Content: '#000000',
    AppMenu_Header: '#fff',
    ResponsiveDrawer_Header: '#2196f3',
}