export const FirebaseConfig = {
	"projectId": "gogo-2dcba",
	"appId": "1:865840962951:web:983ef17d33a6d758c70722",
	"databaseURL": "https://gogo-2dcba-default-rtdb.firebaseio.com",
	"storageBucket": "gogo-2dcba.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAFIDZoj9g6cnd_HHt4AZZCYgslvAyKRkc",
	"authDomain": "gogo-2dcba.firebaseapp.com",
	"messagingSenderId": "865840962951",
	"measurementId": "G-L5GJPRJMT9"
};